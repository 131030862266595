import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'main',
  templateUrl: './main.html'
})
export class MainComponent implements OnInit {

  ngOnInit(): void {
    document.location = "https://foundation.xrpl.org/token-assessment-framework/"
  }
}
