import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { XummTypes } from 'xumm-sdk';

@Injectable()
export class XummService {
    constructor(private app: AppService) {}

    isTestMode = false;
    xrplServicesBackendURL = this.isTestMode ? 'http://localhost:4001' : 'https://assessments.api.xrplf.org';

    async submitPayload(payload:XummTypes.XummPostPayloadBodyJson): Promise<XummTypes.XummPostPayloadResponse | null> {
        try {
            console.log("submitting payload: " + JSON.stringify(payload));
            return this.app.post(this.xrplServicesBackendURL+"/api/v1/platform/payload", payload);
        } catch(err) {
            //console.log("error: ");
            console.log(JSON.stringify(err))
            return null;
        }
    }

    async getPayloadInfo(payloadId:string): Promise<XummTypes.XummGetPayloadResponse | null> {
        try {
            return this.app.get(this.xrplServicesBackendURL+"/api/v1/platform/payload/"+payloadId);
        } catch(err) {
            console.log(JSON.stringify(err))
            return null;
        }
    }

    async deletePayload(payloadId:string): Promise<XummTypes.XummDeletePayloadResponse | null> {
        try {
            return this.app.delete(this.xrplServicesBackendURL+"/api/v1/platform/payload/"+payloadId);
        } catch(err) {
            console.log(JSON.stringify(err))
            return null;
        }
    }

    async checkSignIn(payloadId:string): Promise<any> {
        try {
            return this.app.get(this.xrplServicesBackendURL+"/api/v1/check/signin/"+payloadId);
        } catch(err) {
            console.log(JSON.stringify(err))
            return { error: true, success: false, testnet:false }
        }
    }

    async isSignable(issuer:string, currency:string): Promise<boolean> {
        try {
            return this.app.get(this.xrplServicesBackendURL+"/api/v1/survey/signable/"+issuer+"/"+currency);
        } catch(err) {
            console.log(JSON.stringify(err))
            return false
        }
    }

    async isCompleted(issuer:string, currency:string): Promise<boolean> {
        try {
            return this.app.get(this.xrplServicesBackendURL+"/api/v1/survey/completed/"+issuer+"/"+currency);
        } catch(err) {
            console.log(JSON.stringify(err))
            return false
        }
    }

    async initiateMicroPayment(issuer: string, currency: string): Promise<any> {
        try {
            return this.app.get(this.xrplServicesBackendURL+"/api/v1/xrpl/micropayment/"+issuer+"/"+currency);
        } catch(err) {
            console.log(JSON.stringify(err))
            return { error: true, success: false, testnet:false }
        }
    }
}