import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutes } from './app.routes';

import { AppComponent } from './app.component';
import { TopbarComponent } from './topbar';
import { MainComponent } from './routes/main'
import { SignComponent } from './routes/sign';

import { AppService } from './app.service'
import { XummService } from './xumm.service';

import { DeviceDetectorService } from 'ngx-device-detector';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { QRCodeModule } from 'angular2-qrcode';

@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    MainComponent,
    SignComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    AppRoutes,
    MatButtonModule,
    MatInputModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    QRCodeModule
  ],
  providers: [
    AppService,
    XummService,
    DeviceDetectorService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
