<!-- Toolbar -->
<div fxLayout="column">
  <mat-toolbar fxLayout="row" fxLayout.xs="column" color="primary" style="background-color:#2d8622;" class="mat-elevation-z4">
    <mat-toolbar-row fxLayout="row">
      <img
        width="40"
        alt="Angular Logo"
        src="../../assets/xrplf_logo.png"
      />
      <div style="padding-left: 1em;">
        <label>XRP Ledger Foundation</label>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>