<div class="content" role="main" fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="center center">

    <div *ngIf="loadingData" fxLayout="row" fxLayoutGap="0.5em">
        <h3>Loading ...</h3>
        <mat-progress-spinner mode="indeterminate" diameter=50></mat-progress-spinner>
    </div>

    <div *ngIf="!signSuccess && !signFailed && !isMakingMicroPayment && !surveyAlreadySigned() && !loadingData" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em" style="padding: 1em">
        <h2>Ownership confirmation</h2>
        <h3>To verify the token ownership, please enter the issuer account and currency code</h3>
        <label style="color: red"><b>You have a maximum of 15 minutes to sign your questionnaire.</b></label>
        <label>If you sign later, you will need to do the questionnaire again!</label>
        <div fxLayout="column" fxLayoutGap="0.3em" fxLayoutAlign="center center">
            <input #tokenissuer name="tokenissuer" placeholder="Issuer Account ( r... )" [(ngModel)]="tokenissuerInput" [disabled]="loadingData" style="border-radius: 0.3rem; width: 300px;">
            <label *ngIf="tokenissuerInput && tokenissuerInput.length > 0 && !isValidXRPAddress(tokenissuerInput)" style="color: red; font-size: 0.8em;">Please enter a valid XRP Ledger account.</label>
        </div>
        <div fxLayout="column" fxLayoutGap="0.3em" fxLayoutAlign="center center">
            <input #tokencurrency name="tokencurrency" placeholder="Token Currency Code ( 3 letter or HEX code )" [(ngModel)]="tokencurrencyInput" [disabled]="loadingData" style="border-radius: 0.3rem; width: 300px;">
            <label *ngIf="tokencurrencyInput && tokencurrencyInput.length > 3 && !isHex(tokencurrencyInput)" style="color: red; font-size: 0.8em;">Please provide your currency code in HEX format, as you did in the questionnaire.</label>
        </div>
        <div *ngIf="tokenissuerInput && isValidXRPAddress(tokenissuerInput) && tokencurrencyInput && (tokencurrencyInput.length == 3 || (tokencurrencyInput.length > 3 && isHex(tokencurrencyInput)))" fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="center center">
            <div *ngIf="loadingXUMM || loadingData" fxLayout="row" fxLayoutGap="0.5em">
            <label>Loading ...</label>
            <mat-progress-spinner mode="indeterminate" diameter=25></mat-progress-spinner>
            </div>

            <button mat-raised-button [disabled]="loadingXUMM || loadingData || !tokenissuerInput || !tokencurrencyInput" (click)="signWithXumm()">
                Sign with XUMM
            </button>

            <h3>No XUMM?</h3>

            <button mat-raised-button [disabled]="loadingXUMM || loadingData || !tokenissuerInput || !tokencurrencyInput" (click)="makeMicroPayment()">
                Make Micropayment
            </button>
        </div>
    </div>

    <div *ngIf="surveyAlreadySigned() && !loadingData && !loadingXUMM">
        <h2 style="color: red;">No questionnaire found which can be signed!</h2>
    </div>

    <div *ngIf="!surveyAlreadySigned() && !isMakingMicroPayment && !loadingData">

        <div *ngIf="signSuccess" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em">
            <h2>You have successfully signed as being either the issuing account, or related to the same for the token:</h2>
            <div fxLayout="row">
                <div fxLayout="column" fxLayoutAlign="start center">
                    <label><b>Issuer:</b></label>
                    <label><b>Currency:</b></label>
                </div>
                <div fxLayout="column" fxLayoutAlign="start center">
                    <label><code>{{tokenissuerInput}}</code></label>
                    <label><code>{{tokencurrencyInput}}</code></label>
                </div>
            </div>
            <br/><br/>
            <label>Your signed self-assessment will be available in a few minutes here:</label>
            <a referrerpolicy="no-referrer" href="https://foundation.xrpl.org/token-assessment-framework/browse-token-self-assessments/">Browse self-assessments</a>
            <label>You will be redirected soon...</label>
        </div>
        
        <div *ngIf="signFailed" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em">
            <div *ngIf="signable && !completed" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.5em">
                <h2 style="color: red;">We could not confirm that you are the owner of this token. You might have signed with a wrong account.</h2>
                <h3 style="color: red;">Please sign with your issuer account or one of the business/related accounts your entered in the questionnaire</h3>
            </div>
            <div *ngIf="!signable && !completed" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.5em">
                <h2 style="color: red;">No signable questionnaire found for this Issuer / Token combination.</h2>
                <h3 style="color: red;">Please be aware that you only have 15 minutes to sign your questionnaire once submitted.</h3>
                <label>You can simply redo the questionnaire and sign it right away.</label>
            </div>
        </div>
    </div>

    <div *ngIf="!surveyAlreadySigned() && isMakingMicroPayment && !loadingData" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em" style="padding: 1em">
        <div>
            <h2>Please make a micro payment with the following details to confirm your token ownership.</h2>
        </div>
        <div>
            <label>Make sure you pay with the issuer account or distribution account!</label>
        </div>
        <div *ngIf="microPaymentDestination && microPaymentDestinationTag && microPaymentAmount">
            <div fxShow fxHide.xs style="display: grid; grid-template-columns: auto auto auto; align-items: center; padding: 3em;">
                <div style="display: grid; grid-template-rows: 5rem 5rem 5rem; align-items: center; padding: 3em;">
                    <div><label><b>Destination:</b></label></div>
                    <div><label><b>Destination-Tag:</b></label></div>
                    <div><label><b>Amount:</b></label></div>
                </div>
                <div style="display: grid; grid-template-rows: 5rem 5rem 5rem; align-items: center; padding: 3em;">
                    <div><label><b><code>{{microPaymentDestination}}</code></b></label></div>
                    <div><label><b><code>{{microPaymentDestinationTag && microPaymentDestinationTag}}</code></b></label></div>
                    <div><label><b><code>{{microPaymentAmount}} XRP</code></b></label></div>
                </div>
                <div style="display: grid; grid-template-rows: 5rem 5rem 5rem; align-items: center; padding: 3em;">
                    <div>
                        <div *ngIf="showDestinationQr && !showDestinationTagQr && !showAmountQr"><qr-code [value]="microPaymentDestination.toString()" [size]="100"></qr-code></div>
                        <button *ngIf="!showDestinationQr" mat-raised-button [disabled]="showDestinationQr" (click)="showDestinationQr = true; showDestinationTagQr = showAmountQr = false;">Show Destination QR</button>
                    </div>                
                    <div>
                        <div *ngIf="!showDestinationQr && showDestinationTagQr && !showAmountQr"><qr-code [value]="microPaymentDestinationTag && microPaymentDestinationTag.toString()" [size]="100"></qr-code></div>
                        <button *ngIf="!showDestinationTagQr" mat-raised-button [disabled]="showDestinationTagQr" (click)="showDestinationTagQr = true; showDestinationQr = showAmountQr = false;">Show DestinationTag QR</button>
                    </div>
                    <div>
                        <div *ngIf="!showDestinationQr && !showDestinationTagQr && showAmountQr"><qr-code [value]="microPaymentAmount.toString()" [size]="100"></qr-code></div>
                        <button *ngIf="!showAmountQr" mat-raised-button [disabled]="showAmountQr" (click)="showAmountQr = true; showDestinationQr = showDestinationTagQr = false;">Show Amount QR</button>
                    </div>
                </div>
            </div>

            <div fxShow.xs fxHide style="display: grid; grid-template-rows: auto auto auto; align-items: center; padding: 3em;">
                <div style="display: grid; grid-template-rows: 2rem 4rem 5rem; align-items: center; justify-items: center; padding: 3em;">
                    <div><label><b>Destination:</b></label></div>
                    <div><label><b><code>{{microPaymentDestination}}</code></b></label></div>
                    <div>
                        <div *ngIf="showDestinationQr && !showDestinationTagQr && !showAmountQr"><qr-code [value]="microPaymentDestination.toString()" [size]="100"></qr-code></div>
                        <button *ngIf="!showDestinationQr" mat-raised-button [disabled]="showDestinationQr" (click)="showDestinationQr = true; showDestinationTagQr = showAmountQr = false;">Show Destination QR</button>
                    </div>                
                    
                </div>
                <div style="display: grid; grid-template-rows: 2rem 4rem 5rem; align-items: center; justify-items: center; padding: 3em;">
                    <div><label><b>Destination-Tag:</b></label></div>
                    <div><label><b><code>{{microPaymentDestinationTag && microPaymentDestinationTag}}</code></b></label></div>
                    <div>
                        <div *ngIf="!showDestinationQr && showDestinationTagQr && !showAmountQr"><qr-code [value]="microPaymentDestinationTag && microPaymentDestinationTag.toString()" [size]="100"></qr-code></div>
                        <button *ngIf="!showDestinationTagQr" mat-raised-button [disabled]="showDestinationTagQr" (click)="showDestinationTagQr = true; showDestinationQr = showAmountQr = false;">Show DestinationTag QR</button>
                    </div>                    
                </div>
                <div style="display: grid; grid-template-rows: 2rem 4rem 5rem; align-items: center; justify-items: center; padding: 3em;">
                    <div><label><b>Amount:</b></label></div>
                    <div><label><b><code>{{microPaymentAmount}} XRP</code></b></label></div>
                    <div>
                        <div *ngIf="!showDestinationQr && !showDestinationTagQr && showAmountQr"><qr-code [value]="microPaymentAmount.toString()" [size]="100"></qr-code></div>
                        <button *ngIf="!showAmountQr" mat-raised-button [disabled]="showAmountQr" (click)="showAmountQr = true; showDestinationQr = showDestinationTagQr = false;">Show Amount QR</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div fxLayout="column" fxLayoutGap="1em" style="padding: 1em">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0.5em" fxLayoutAlign="center center">
                <h3>Waiting for Micropayment ...</h3>
                <mat-progress-spinner mode="indeterminate" diameter=50></mat-progress-spinner>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0.5em" fxLayoutAlign="center center">
                <label>Payment request expires in: </label>
                <label>{{microPaymentTimeout}}</label>
                <label> Seconds</label>
            </div>
        </div>
    </div>
</div>
