import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { MainComponent } from './routes/main';
import { SignComponent } from './routes/sign';

export const routes: Routes = [
    {path: '', component: MainComponent},
    {path: 'sign', component: SignComponent},
    {path: '**', redirectTo: ''}
];

export const AppRoutes: ModuleWithProviders<any> = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });