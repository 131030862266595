import { Component, ViewChild, OnInit } from '@angular/core';
import { XummTypes } from 'xumm-sdk';
import { XummService } from '../xumm.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router, ActivatedRoute } from '@angular/router';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Encode } from 'xrpl-tagged-address-codec';

@Component({
  selector: 'sign',
  templateUrl: './sign.html'
})
export class SignComponent implements OnInit {

  constructor(private xummApi: XummService, private deviceDetector: DeviceDetectorService, private route: ActivatedRoute, private router: Router) {
  }

  @ViewChild('tokenissuer') tokenissuer: any;
  tokenissuerInput: string | undefined;

  @ViewChild('tokenissuer') tokencurrency: any;
  tokencurrencyInput: string | undefined;

  loadingData:boolean = false;
  loadingXUMM:boolean = false;
  signSuccess:boolean = false;
  signFailed:boolean = false;

  isMakingMicroPayment:boolean = false;
  microPaymentDestination:string = null;
  microPaymentDestinationTag:string = null;
  microPaymentAmount:number = null;

  //websocket for 
  websocket: WebSocketSubject<any>;

  microPaymentTimeout:number = 0;

  redirectLocation = "https://foundation.xrpl.org/token-assessment-framework";
  redirectLocationSign = "https://token-assessment.xrplf.org/sign";

  showDestinationQr:boolean = false;
  showDestinationTagQr:boolean = false;
  showAmountQr:boolean = false;

  signable:boolean = false;
  completed: boolean = false;

  ngOnInit() {
    this.route.queryParams.subscribe(async params => {

      //remove payload from url and navigate again
      if(params && params.payloadid) {
        this.loadingData = true;
        let state = {
          payloadid: params['payloadid']
        }
  
        let newParams:any = {};
  
        for (var param in params) {
          if (params.hasOwnProperty(param) && param != "payloadid") {
            newParams[param] = params[param];
          }
        }

        setTimeout(() => {
          this.router.navigate(
            ['/sign'], 
            { relativeTo: this.route, queryParams: newParams, state: state }
          );
        }, 2000)

        return;
      }

      let payloadid = null;
  
      if(this.router.getCurrentNavigation() && this.router.getCurrentNavigation()?.extras && this.router.getCurrentNavigation()?.extras.state != null && this.router.getCurrentNavigation()?.extras.state.payloadid) {
        payloadid = this.router.getCurrentNavigation().extras.state.payloadid
      }
  
      if(payloadid) {
        this.loadingData = true;
        console.log(JSON.stringify("RECEIVED PAYLOAD ID: " + payloadid));
        let signInCheck = await this.xummApi.checkSignIn(payloadid);

        if(signInCheck.success) {
          //check if we have a survey with this data
          let payload = await this.xummApi.getPayloadInfo(payloadid);
          let issuer = payload?.custom_meta?.blob?.issuer;
          let currency = payload?.custom_meta?.blob?.currency;

          if(issuer && currency && typeof issuer === 'string' && typeof currency === 'string') {
            this.signable =  await this.xummApi.isSignable(issuer, currency);
            this.completed = await this.xummApi.isCompleted(issuer, currency);

            console.log("survey signed: " + JSON.stringify(this.completed));

            this.tokenissuerInput = issuer;
            this.tokencurrencyInput = currency;

            if(this.completed) {
              this.signSuccess = true;

              setTimeout(() => {
                document.location = this.redirectLocation;
              }, 15000)
            } else {
              this.signFailed = true;

              if(!this.signable) {
                setTimeout(() => {
                  document.location = this.redirectLocation;
                }, 15000)
              } else {
                setTimeout(() => {
                  document.location = this.redirectLocationSign;
                }, 15000)
              }
            }
          }
        } else {
          this.signSuccess = false;
          this.signFailed = true;

          setTimeout(() => {
            document.location = this.redirectLocation;
          }, 15000)
        }

        this.loadingData = false;
      }
    });
  }

  async signWithXumm() {
    try {
      this.loadingXUMM = true;

      this.signable = await this.xummApi.isSignable(this.tokenissuerInput?.trim(), this.tokencurrencyInput?.trim());

      if(!this.signable) {
        //wait 5 sec and try again!
        await this.wait(5000);

        this.signable = await this.xummApi.isSignable(this.tokenissuerInput?.trim(), this.tokencurrencyInput?.trim());

        if(!this.signable) {
          this.loadingData = false;
          this.signFailed = true;

          return;
        }
      }

      let payload:XummTypes.XummPostPayloadBodyJson = {
        options: {
            expire: 5,
            return_url: {
              app: !this.deviceDetector.isDesktop() ? "https://token-assessment.xrplf.org/sign/?payloadid={id}" : undefined,
              web: this.deviceDetector.isDesktop() ? "https://token-assessment.xrplf.org/sign/?payloadid={id}" : undefined
            }
        },
        txjson: {
            TransactionType: "SignIn"
        },
        custom_meta: {
          instruction: "Please sign with the Issuer Account or one of the 'Business Accounts' you entered in the survey.",
          blob: {
            issuer: this.tokenissuerInput?.trim(),
            currency: this.tokencurrencyInput?.trim()
          }
        }
      }

      let xummResponse = await this.xummApi.submitPayload(payload);

      //redirect user to sign request
      if(xummResponse?.next?.always) {
        document.location = xummResponse.next.always;      
      }
    } catch(err) {
      console.log("ERR signing with XUMM");
      console.log(JSON.stringify(err));
    }
  }

  async makeMicroPayment() {
    
    try {
      this.loadingData = true;
      this.isMakingMicroPayment = true;

      this.signable = await this.xummApi.isSignable(this.tokenissuerInput?.trim(), this.tokencurrencyInput?.trim());

      if(!this.signable) {
        //wait 5 sec and try again!
        await this.wait(5000);

        this.signable = await this.xummApi.isSignable(this.tokenissuerInput?.trim(), this.tokencurrencyInput?.trim());

        if(!this.signable) {
          console.log("NO SURVEY FOUND!")
          this.loadingData = false;
          this.signFailed = true;
          this.isMakingMicroPayment = false;

          return;
        }
      }

      let microPayment = await this.xummApi.initiateMicroPayment(this.tokenissuerInput?.trim(), this.tokencurrencyInput?.trim());

      this.microPaymentTimeout = Math.round(microPayment.timeout/1000);

      setTimeout(async () => {
        while(this.microPaymentTimeout > 0) {
          this.microPaymentTimeout--;
          await this.wait(1000);
        }
      },1);

      this.microPaymentDestination = microPayment.destination;
      this.microPaymentDestinationTag = microPayment.destinationTag;
      this.microPaymentAmount = microPayment.amount/1000000;

      this.showDestinationQr = true;
      this.showDestinationTagQr = this.showAmountQr = false;

      this.websocket = webSocket("wss://xrplcluster.com");

      let timeout = setTimeout(async () => {
        try {
            //cancel websocket after timeout
            await this.websocket.complete()
            this.websocket = null;
            console.log("xrplCLient successfully closed!");

            setTimeout(() => {
              document.location = this.redirectLocation;
            }, 15000)
        } catch(err) {
            console.log(err);
        }
      }, microPayment.timeout);

      this.websocket.asObservable().subscribe(async stream => {
        if(stream?.engine_result === 'tesSUCCESS') {
          let transaction = stream.transaction;
          console.log("received payment: " + JSON.stringify(transaction));
          //check if parameters are okay!
          if(transaction && transaction.TransactionType === 'Payment' && microPayment.destination === transaction.Destination && microPayment.destinationTag === transaction.DestinationTag && microPayment.amount === transaction.Amount) {
              //everything is okay, check if the sender is a known account!
              this.loadingData = true;
              setTimeout(async () => {
                this.completed = await this.xummApi.isCompleted(this.tokenissuerInput?.trim(), this.tokencurrencyInput?.trim());

                if(this.completed) {
                    //update survey
                    this.signSuccess = true;
                    this.signFailed = false;
                    console.log("MICROPAYMENT RECEIVED AND SURVEY UPDATED")

                    setTimeout(() => {
                      document.location = this.redirectLocation;
                    }, 10000)

                } else {
                    this.signSuccess = false;
                    this.signFailed = true;

                    if(!this.signable) {
                      setTimeout(() => {
                        document.location = this.redirectLocation;
                      }, 15000)
                    } else {
                      setTimeout(() => {
                        document.location = this.redirectLocationSign;
                      }, 15000)
                    }
                }

                this.isMakingMicroPayment = false;
                this.loadingData = false;                

              }, 5000);

              await this.websocket.complete()
              this.websocket = null;
              clearTimeout(timeout);
          }
        }
      });

      let subscribeStream:any = {
        "command": 'subscribe',
        "accounts": [microPayment.destination]
      }

      this.websocket.next(subscribeStream);

      this.loadingData = false;

    } catch(err) {
      console.log("ERR WAITING FOR PAYMENT");
      console.log(JSON.stringify(err));
    }
  }

  surveyAlreadySigned(): boolean {
    return !this.signable && this.completed;
  }

  isHex(string: string): boolean {
    return /^[0-9A-Fa-f]*$/.test(string);
  }

  isValidXRPAddress(address: string): boolean {
    try {
      //console.log("encoding address: " + address);
      let xAddress = Encode({account: address});
      //console.log("xAddress: " + xAddress);
      return xAddress && xAddress.length > 0;
    } catch(err) {
      //no valid address
      //console.log("err encoding " + err);
      return false;
    }
}

  wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
